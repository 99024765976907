<template>
  <b-navbar toggleable="lg" type="light" variant="light">
      <b-navbar-toggle target="nav-collapse" class="ml-auto"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item href="#hero">Home</b-nav-item>
          <b-nav-item href="#skills">Skills</b-nav-item>
          <b-nav-item href="#resume">Resume</b-nav-item>
          <b-nav-item href="#projects">Projects</b-nav-item>
          <b-nav-item href="#testimonials">Testimonials</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
</template>

<script>
export default {
  name: 'NavBar'
}
</script>


<style scoped lang="scss">
  @import '../assets/css/index.scss';

  nav {
    position: fixed;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    z-index: 2;
  }

  .ml-auto {
    margin-left: auto !important;
  }

  li {
    @media screen and (min-width: 992px) {
      position: relative;
      font-weight: bold;
      word-break: keep-all;
      white-space: nowrap;

      // border on bottom of name
      &:hover:before {
        content: '';
        position: absolute;
        bottom: 0.6em;
        height: 0.5em;
        width: 100%;
        z-index: -1;
        display: inline-block;
        background-color: rgba($colorSecondary, 0.4);
      }
    }
  }
</style>