<template>
  <section id="testimonials">
      <div class="container">

        <div class="section-title">
          <h2>Testimonials</h2>
        </div>
        <b-carousel
          :interval="4000"
          controls
          indicators
          img-width="100%"
          img-height="50"
        >
          <b-carousel-slide>
            <template #img>
              <b-card>
                <b-card-title>
                  Ana Makarochkina
                </b-card-title>
                <b-card-sub-title>
                  Frontend Engineer
                  <span>Worked on the same team as a FE Engineer at Dapper Labs</span>
                </b-card-sub-title>
                
                <b-card-text>
                  <span class="quote-left"></span>
                  I've had the pleasure of working with Fernanda on NBA TopShot since around December 2019, and it's been an amazing ride. She's been a rockstar in contributing to the most important parts of the project, especially when we were deep into testing with MSW tests and experimenting like crazy.
                  <br/>
                  Fernanda has been a powerhouse in developing features like pack purchases, where she nailed everything from multi-pack to rebound pack purchasing. She also built the collectors directory, Top Shot 50, parallel editions, and the wishlist feature, which are still used on the NBA Top Shot site today. Her ability to pick up and build full-stack features with Firebase db, GraphQL API, and Next.js apps has been super impressive.
                  <br/>
                  As a teammate, Fernanda is the go-to person for solid pull request reviews and always ready to answer any questions. She's got a great eye for improving features and genuinely cares about the users. Her attention to detail in building features is top-notch, leaving little room for QA feedback.
                  <br/>
                  Fernanda is always ahead of the game, thinking about potential issues and ensuring a smooth experience for users. She's incredibly supportive of everyone on the team and works well with cross-functional team members and internal customers like campaign producers.
                  <br/>
                  Her work ethic is off the charts, taking ownership of her tasks and paying close attention to the smallest details and possible edge cases. Fernanda's communication skills are fantastic. She's always proactive in sharing her work status and making sure nothing is missed, fostering a transparent and open environment in the team.
                  <br/>
                  Working with Fernanda is always a joy, and she's great at tackling tough problems together while being open to constructive feedback. Even though she's no longer at Dapper Labs, she's still a top 3 contributor to the NBA TopShot codebase, which says a lot about her lasting impact.
                  <span class="quote-right"></span>
                </b-card-text>
              </b-card>
            </template>
          </b-carousel-slide>  <!-- End testimonial item -->
          <b-carousel-slide>
            <template #img>
              <b-card>
                <b-card-title>
                  Austin Samsel
                </b-card-title>
                <b-card-sub-title>
                  Staff Software Engineer
                  <span>Worked on the same team as a FE Engineer at Dapper Labs</span>
                </b-card-sub-title>
                <b-card-text>
                  <span class="quote-left"></span>
                  Fernanda is an exceptional engineer and top-tier talent. We worked together on CryptoKitties and NBA Top Shot where I watched her excel over the years. Her attention to detail: identifying edge cases and potential business risks, helped our teams consistently deliver the right solutions on time. Her adaptability and strong communication skills allowed her to thrive in a fast-paced environment as we built on a bleeding-edge blockchain stack. Her adaptability to business needs, execution of high-quality coding standards, and natural collaboration would make her invaluable to any team in tech.
                  <span class="quote-right"></span>
                </b-card-text>
              </b-card>
            </template>
          </b-carousel-slide>  <!-- End testimonial item -->

          <b-carousel-slide>
            <template #img>
              <b-card>
                <b-card-title>
                  Gavin Ching
                </b-card-title>
                <b-card-sub-title>
                  Software Engineer
                  <span>Worked on the same team as a FE Engineer at Dapper Labs</span>
                </b-card-sub-title>
                <b-card-text>
                  <span class="quote-left"></span>
                  When I first joined NBA Top Shot at Dapper Labs, Fernanda was my mentor as a Front End Engineer and onboarded me to the team. Our team iterated quickly and it was a very fast-paced environment. Fernanda not only completed her tasks quickly for our team and other teams, she also gave me constructive and thorough feedback on my work. We would always collaborate on how we should both improve and I've learnt so much from her. At times when the requirements would be unclear, Fernanda would take ownership in finalizing scope from other stakeholders or make decisions herself to unblock the team.
                  <br>
                  It was a great privilege to work and learn from Fernanda. I would highly recommend Fernanda to anyone looking for an amazing blockchain engineer and software developer, in addition to someone who is able to mentor those on your team.
                  <span class="quote-right"></span>
                </b-card-text>
              </b-card>
            </template><!-- End testimonial item -->
          </b-carousel-slide>
          
          <b-carousel-slide>
            <template #img>
              <b-card>
                <b-card-title>
                  Julian Palhof
                </b-card-title>
                <b-card-sub-title>
                  Product Designer
                  <span>Worked on the same team as a Designer at Dapper Labs</span>
                </b-card-sub-title>

                <b-card-text>
                  <span class="quote-left"></span>
                  I had the pleasure of working with Fernanda at Dapper Labs and always appreciated her attention to detail and speed. Combining both skills is a dream for a product designer, hoping their designs are implemented correctly. Not only is she amazing at engineering beautiful front end, she was able to guide me in adjustments based on unforeseen obstacles and improve the final products on time. I will always appreciate her hard work, expertise, and positive attitude under immense pressure. I think she would be a huge level up for any Engineering org! 
                  <span class="quote-right"></span>
                </b-card-text>
              </b-card>
            </template><!-- End testimonial item -->
          </b-carousel-slide>
          <b-carousel-slide>
            <template #img>
              <b-card>
                <b-card-title>
                  Andrew Nguyen
                </b-card-title>
                <b-card-sub-title>
                  Senior Software Engineer
                  <span>Worked on the same team as a BE Engineer at Dapper Labs</span>
                </b-card-sub-title>

                <b-card-text>
                  <span class="quote-left"></span>
                  I had the privilege of working alongside Fernanda at Dapper Labs on NBA Top Shot. As a Front-End Engineer, she consistently demonstrated exceptional skill and an unwavering attention to detail. Her work was invariably of the highest quality, contributing significantly to the success of our project. Fernanda’s technical expertise, combined with her collaborative spirit, makes her an invaluable asset to any engineering team. I wholeheartedly recommend her with the highest confidence for any future endeavor she chooses to pursue.
                  <span class="quote-right"></span>
                </b-card-text>
              </b-card>
            </template><!-- End testimonial item -->
          </b-carousel-slide>
          <b-carousel-slide>
            <template #img>
              <b-card>
                <b-card-title>
                  Yusuf Bayrak
                </b-card-title>
                <b-card-sub-title>
                  Web3 UX & Feature Research
                  <span>Worked on the same team as a Product and Design Lead at Dapper Labs</span>
                </b-card-sub-title>

                <b-card-text>
                  <span class="quote-left"></span>
                  It was a pleasure working with Fernanda on NBA Top Shot. Her top-tier front-end development skills, speed in coding ⚡, and consistent communication 🗨️ greatly contributed to the project’s success. She always delivered features early, exceeding expectations. A huge thank you to Fernanda for her excellent work 🌟. I hope we have the chance to work together again soon 🤞.
                  <span class="quote-right"></span>
                </b-card-text>
              </b-card>
            </template><!-- End testimonial item -->
          </b-carousel-slide>
          <b-carousel-slide>
          <template #img>
              <b-card>
                <b-card-title>
                  Renan Sgorlom
                </b-card-title>
                <b-card-sub-title>
                  Senior Software Engineer
                  <span>Worked on the same team as a FE Engineer at Dapper Labs</span>
                </b-card-sub-title>

                <b-card-text>
                  <span class="quote-left"></span>
                  I had the pleasure of working closely with Fernanda on CryptoKitties and NBA Top Shot, and she truly stands out as an exceptional engineer. Her meticulous attention to detail and ability to identify edge cases ensured our teams consistently delivered solutions on time. Fernanda's adaptability, strong communication skills, and commitment to high coding standards make her an invaluable asset to any tech team.
                  <br />
                  During our collaboration on NBA Top Shot, Fernanda's top-tier front-end development skills and swift coding significantly contributed to the project's success. I highly recommend Fernanda to any team seeking a talented and hardworking engineer. It was a pleasure working with her, and I look forward to the opportunity to collaborate again in the future.
                  <span class="quote-right"></span>
                </b-card-text>
              </b-card>
            </template><!-- End testimonial item -->
          </b-carousel-slide>
        </b-carousel>
      </div>
    </section>
</template>

<script>
export default {
  name: 'TestimonialsSection'
}
</script>

<style lang="scss">
  @import '../assets/css/index.scss';
    
  .card {
    justify-content: center !important;
    height: auto;
    min-height: 390px;
    border: none !important;
    background-color: transparent !important;
  }

  .card-body {
    margin: 10px;
    text-align: center;

    @media screen and (min-width: 768px) {
      margin: 10px 64px;
    }

    h4 {
      font-size: 20px;
      font-weight: bold;
      margin: 10px 0 5px 0;
      color: $darkGray;
    }

    h6 {
      margin: 0 0 15px 0;
      font-size: 14px;
      font-weight: bold;
      color: $darkGray;

      span {
        display: block;
        font-weight: normal;
      }
    }

    p {
      font-style: italic;
      margin: 0 auto 15px auto;
    }
  }

  .carousel-control-prev {
    justify-content: left !important;
    padding-left: 0px !important;
  }

  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23856CB0' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
  }

  .carousel-control-next {
    justify-content: right !important;
    padding-right: 0px !important;
  }

  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23856CB0' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
  }

  .carousel-indicators {
    position: static !important;
  }

  .carousel-indicators li {
    width: 14px !important;
    height: 14px !important;
    border-radius: 50% !important;
    background-color: $colorSecondary !important;
  }

  .quote-left {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M6.5 10c-.223 0-.437.034-.65.065c.069-.232.14-.468.254-.68c.114-.308.292-.575.469-.844c.148-.291.409-.488.601-.737c.201-.242.475-.403.692-.604c.213-.21.492-.315.714-.463c.232-.133.434-.28.65-.35l.539-.222l.474-.197l-.485-1.938l-.597.144c-.191.048-.424.104-.689.171c-.271.05-.56.187-.882.312c-.318.142-.686.238-1.028.466c-.344.218-.741.4-1.091.692c-.339.301-.748.562-1.05.945c-.33.358-.656.734-.909 1.162c-.293.408-.492.856-.702 1.299c-.19.443-.343.896-.468 1.336c-.237.882-.343 1.72-.384 2.437c-.034.718-.014 1.315.028 1.747c.015.204.043.402.063.539l.025.168l.026-.006A4.5 4.5 0 1 0 6.5 10zm11 0c-.223 0-.437.034-.65.065c.069-.232.14-.468.254-.68c.114-.308.292-.575.469-.844c.148-.291.409-.488.601-.737c.201-.242.475-.403.692-.604c.213-.21.492-.315.714-.463c.232-.133.434-.28.65-.35l.539-.222l.474-.197l-.485-1.938l-.597.144c-.191.048-.424.104-.689.171c-.271.05-.56.187-.882.312c-.317.143-.686.238-1.028.467c-.344.218-.741.4-1.091.692c-.339.301-.748.562-1.05.944c-.33.358-.656.734-.909 1.162c-.293.408-.492.856-.702 1.299c-.19.443-.343.896-.468 1.336c-.237.882-.343 1.72-.384 2.437c-.034.718-.014 1.315.028 1.747c.015.204.043.402.063.539l.025.168l.026-.006A4.5 4.5 0 1 0 17.5 10z' fill='%23856CB0'/%3E%3C/svg%3E");
  }

  .quote-right {
    display: inline-block;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M21.95 8.721l-.025-.168l-.026.006A4.5 4.5 0 1 0 17.5 14c.223 0 .437-.034.65-.065c-.069.232-.14.468-.254.68c-.114.308-.292.575-.469.844c-.148.291-.409.488-.601.737c-.201.242-.475.403-.692.604c-.213.21-.492.315-.714.463c-.232.133-.434.28-.65.35l-.539.222l-.474.197l.484 1.939l.597-.144c.191-.048.424-.104.689-.171c.271-.05.56-.187.882-.312c.317-.143.686-.238 1.028-.467c.344-.218.741-.4 1.091-.692c.339-.301.748-.562 1.05-.944c.33-.358.656-.734.909-1.162c.293-.408.492-.856.702-1.299c.19-.443.343-.896.468-1.336c.237-.882.343-1.72.384-2.437c.034-.718.014-1.315-.028-1.747a7.028 7.028 0 0 0-.063-.539zm-11 0l-.025-.168l-.026.006A4.5 4.5 0 1 0 6.5 14c.223 0 .437-.034.65-.065c-.069.232-.14.468-.254.68c-.114.308-.292.575-.469.844c-.148.291-.409.488-.601.737c-.201.242-.475.403-.692.604c-.213.21-.492.315-.714.463c-.232.133-.434.28-.65.35l-.539.222c-.301.123-.473.195-.473.195l.484 1.939l.597-.144c.191-.048.424-.104.689-.171c.271-.05.56-.187.882-.312c.317-.143.686-.238 1.028-.467c.344-.218.741-.4 1.091-.692c.339-.301.748-.562 1.05-.944c.33-.358.656-.734.909-1.162c.293-.408.492-.856.702-1.299c.19-.443.343-.896.468-1.336c.237-.882.343-1.72.384-2.437c.034-.718.014-1.315-.028-1.747a7.571 7.571 0 0 0-.064-.537z' fill='%23856CB0'/%3E%3C/svg%3E");
  }
</style>