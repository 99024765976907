import Vue from 'vue'
import App from './App.vue'
import VueGtag from "vue-gtag";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import './assets/css/index.scss';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: "G-QPQ326GYFL" }
});

new Vue({
  render: h => h(App),
}).$mount('#app')
