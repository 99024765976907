<template>
  <div>
    <a href="mailto:ftoledo.work@gmail.com" class="envelope"><b-icon icon="envelope" scale="1.6"></b-icon></a>
    <a href="https://www.linkedin.com/in/toledofernanda" target="_blank" class="linkedin"><b-icon icon="linkedin" scale="1.6"></b-icon></a>
    <a href="https://github.com/toledofernanda" target="_blank" class="github"><b-icon icon="github" scale="1.6"></b-icon></a>
  </div>
</template>

<script>
export default {
  name: 'SocialLinks'
}
</script>

<style scoped lang="scss">
  div {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    gap: 24px;
  }
</style>