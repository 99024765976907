<template>
  <section id="skills">
      <div class="container" >

        <div id="skillsSection" class="skills-content">
          <h2>Skills</h2>
          
          <div class="row">
            <div class="col-lg-4">
              <span class="skill">Dependable</span>
              <span class="skill">Responsible</span>
            </div>
            <div class="col-lg-4">
              <span class="skill">Attention to detail</span>
              <span class="skill">Fast learner</span>
            </div>
            <div class="col-lg-4">
              <span class="skill">Team player</span>
              <span class="skill">User experience mindset</span>
            </div>
          </div>
        </div>

        <div class="skills-content">
          <h2>Tech Stack</h2>

          <p>Throughout the professional and educational journey, gained hands-on experience and proficiency in a diverse range of technologies and tools. Part of the skill set has been acquired through practical application in real work environments, tailored to meet project requirements for new features and enhancements.</p>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-6">
            <h5 class="title"><b-icon icon="code-slash" /> Interface</h5>
          
            <span class="skill">HTML</span>
            <span class="skill">CSS</span>
            <span class="skill">Responsive design</span>
            <span class="skill">UX / UI</span>
            <span class="skill">Sass</span>
            <span class="skill">Bootstrap</span>
            <span class="skill">Storybook</span>
            <span class="skill">Figma</span>
          </div>

          <div class="col-lg-3 col-md-6">
            <h5 class="title"><b-icon icon="braces" /> Programming</h5>
          
            <span class="skill">JavaScript / TypeScript</span>
            <span class="skill">React</span>
            <span class="skill">Next.js</span>
            <span class="skill">Node.js</span>
            <span class="skill">jQuery</span>
            <span class="skill">Redux</span>
          </div>

          <div class="col-lg-3 col-md-6">
            <h5 class="title"><b-icon icon="files" /> APIs/Database</h5>
          
            <span class="skill">GraphQL</span>
            <span class="skill">REST</span>
            <span class="skill">JSON</span>
            <span class="skill">SQL</span>
            <span class="skill">Firebase</span>
          </div>

          <div class="col-lg-3 col-md-6">
            <h5 class="title">Others</h5>
          
            <span class="skill">Git/Github</span>
            <span class="skill">Agile Methodologies</span>
            <span class="skill">Testing (Jest)</span>
            <span class="skill">Contentful (Headless CMS)</span>
            <span class="skill">WordPress</span>
            <span class="skill">Heroku</span>
            <span class="skill">JIRA/Zenhub</span>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
export default {
  name: 'SkillsSection'
}
</script>

<style scoped lang="scss">
  @import '../assets/css/index.scss';

  .skills-content {
    margin-bottom: 40px;
  }

  .skills-content div {
    @media (max-width: 992px) {
      margin-bottom: 20px;
    }
  }

  .title {
    color: $colorPrimary;
    font-size: 18px;
  }

  div {
    margin-bottom: 14px;
  }

  .skill {
    padding: 6px 0;
    margin: 0;
    text-transform: uppercase;
    display: block;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }

  #skillsSection .skill {
    text-align: center;
  }
</style>