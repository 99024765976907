<template>
  <section id="resume">
      <div class="container" >

        <div class="section-title">
          <h2>Resume</h2>
        </div>

        <div id="resume-content">
          <div id="summary">
            <h3 class="resume-title">Summary</h3>
            <div class="resume-item">
              <h4>Fernanda Toledo</h4>
              <p>
                <em>
                  Front End Developer with over 5 years of experience developing innovative web applications with the latest front end technologies and engaging user experiences.
                </em>
              </p>
              <p>
                <em>
                  Experience working on startups with cross-functional teams for over 11 years, team player, dependable, adaptable, and successful at managing multiple priorities with a positive attitude.
                </em>
              </p>
              <ul>
                <li><b-icon icon="pin-map" />&nbsp; Vancouver, Canada</li>
                <li><b-icon icon="envelope" />&nbsp; <a href="mailto:ftoledo.work@gmail.com">ftoledo.work@gmail.com</a></li>
                <li><b-icon icon="linkedin" />&nbsp; <a href="https://www.linkedin.com/in/toledofernanda/" target="_blank" >in/toledofernanda</a></li>
                <li><b-icon icon="github" />&nbsp; <a href="https://github.com/toledofernanda
                " target="_blank">github/toledofernanda</a></li>
              </ul>
            </div>
          </div>

          <div id="education">
            <h3 class="resume-title">Education</h3>
            <div class="resume-item">
              <h4>Post-Degree in Web and Mobile App Design &amp; Development</h4>
              <div class="locationDate"><em><h5>Langara College - Vancouver, BC</h5></em><span class="tag">2017 - 2018</span></div>

              <div>
                <b>Achievements</b>
                <ul>
                  <li>Dean's Honour Roll - Fall 2018/Summer 2018/Spring 2018</li>
                  <li>Final GPA 4.00 (A)</li>
                </ul>
              </div>

              <div>
                <b>Curriculum</b>
                <ul>
                  <li>Web Development</li>
                    <span class="tag">HTML</span>
                    <span class="tag">CSS</span>
                    <span class="tag">Sass</span>
                    <span class="tag">Bootstrap</span>
                    <span class="tag">Javascript</span>
                    <span class="tag">React</span>
                    <span class="tag">jQuery</span>
                    <span class="tag">UX</span>
                    <span class="tag">UI</span>
                    <span class="tag">Responsive design</span>
                    <span class="tag">Wordpress</span>
                    <span class="tag">Underscores</span>
                    <span class="tag">Node.js</span>
                    <span class="tag">Express.js</span>
                    <span class="tag">SQL</span>
                    <span class="tag">MySQL</span>
                    <span class="tag">SQLite</span>
                    <span class="tag">MongoDB</span>
                    <span class="tag">Firebase</span>

                  <li>Object-Oriented Programming</li>
                    <span class="tag">Javascript</span>
                    <span class="tag">Java</span>

                  <li>Mobile Development</li>
                    <span class="tag">Android Native/Java</span>
                    <span class="tag">iOS/Swift</span>
                    <span class="tag">PhoneGap</span>
                    <span class="tag">Apache Cordova</span>

                  <li>Project Management</li>
                </ul>
              </div>
            </div>
            <div class="resume-item">
              <h4>Internet &amp; Web Technology Certificate</h4>
              <div class="locationDate"><em><h5>Langara College - Vancouver, BC</h5></em> <span class="tag">2016 - 2017</span></div>
          
              <div>
                <b>Achievements</b>
                <ul>
                  <li>Dean's Honour Roll - Spring 2017/Summer 2017</li>
                  <li>Langara Faculty Association Career Program Scholarship - July 2017</li>
                  <li>Final GPA 3.93 (A-)</li>
                </ul>
              </div>
              
              <div>
                <b>Curriculum</b>
                <ul>
                  <li>Web Development</li>
                    <span class="tag">HTML</span>
                    <span class="tag">CSS</span>
                    <span class="tag">LESS</span>
                    <span class="tag">Bootstrap</span>
                    <span class="tag">Javascript</span>
                    <span class="tag">jQuery</span>
                    <span class="tag">Ajax</span>
                    <span class="tag">Express</span>
                    <span class="tag">AngularJS</span>
                    <span class="tag">Node.js</span>
                    <span class="tag">PHP</span>
                    <span class="tag">Wordpress</span>
                    <span class="tag">SQL</span>
                    <span class="tag">MySQL</span>
                    <span class="tag">MongoDB</span>
          
              
                  <li>Program Design and Object-Oriented Programming</li>
                    <span class="tag">Javascript</span>
                    <span class="tag">Java</span>

                  <li>Unix Tools and Scripting</li>

                  <li>Network standards and protocols</li>
                </ul>
              </div>
            </div>
            <div class="resume-item">
              <h4>Bachelor in Advertising &amp; Marketing</h4>
              <div class="locationDate"><em><h5>Mackenzie University - Sao Paulo, Brazil</h5></em><span class="tag">2009 - 2012</span></div>
            </div>
          </div>
            
          <div id="experience">
            <h3 class="resume-title">Professional Experience</h3>
            <div class="resume-item">
              <h4>Frontend Engineer</h4>
              <div class="locationDate"><em><h5>GitLab - Remote</h5></em><span class="tag">2024 - Present</span></div>

              <span class="tag">Vue</span>
              <span class="tag">Javascript</span>
              <span class="tag">HTML</span>
              <span class="tag">CSS</span>
            </div>
            <div class="resume-item">
              <h4>Frontend Engineer</h4>
              <div class="locationDate"><em><h5>Dapper Labs - Vancouver, BC</h5></em><span class="tag">2019 - 2023</span></div>

              <p>In over 4 years, had the opportunity to contribute to the creation and development of <a href="https://nbatopshot.com/" target="_blank">NBA Top Shot</a> and <a href="https://www.cryptokitties.co/" target="_blank">CryptoKitties</a>, showcasing the the skills below:</p>
              
              <ul>
                <li>Developed features for NBA Top Shot’s initial launch, which gained 10M users within a year</li>
                <li>Owned projects and features from the initial planning stages to their successful completion and prioritized tasks based on their impact</li>
                <li>Collaborated with multiple cross-functional teams (FE, BE, QA, designers and product) teams in a fast-paced environment ensuring great communication, seamless integration between application components, and successful feature launches</li>
                <li>Supported multiple live pack drops with the Live Operations team to address any issues that could come up during the drop, which involved thousands of users buying and packs being sold per event</li>
                <li>Dedicated to consistently and continuously enhancing the product and user experience, with a mindset focused on team productivity while keeping code quality and scalability</li>
                <li>Conducted code reviews and provided constructive feedback to optimize code quality and maintainability, as well as received feedback on my designs from senior and staff engineers</li>
                <li>Worked closely with designers to translate UI/UX designs into functional, responsive, user-friendly and pixel-perfect features, and demonstrated design knowledge whenever needed to come up with quick solutions to be unblocked while developing a feature</li>
                <li>Wrote frontend feature ADRs which listed multiple technical suggestions for feature implementation, after thorough investigation and planning, so team members could evaluate efficiency, backend integration, and decide the best approach to that task</li>
              </ul>

              <span class="tag">React</span>
              <span class="tag">Javascript</span>
              <span class="tag">TypeScript</span>
              <span class="tag">HTML</span>
              <span class="tag">CSS</span>
              <span class="tag">Next.js</span>
              <span class="tag">Node.js</span>
              <span class="tag">GraphQL</span>
              <span class="tag">JSON</span>
              <span class="tag">Firebase</span>
              <span class="tag">Jest</span>
              <span class="tag">Storybook</span>
              <span class="tag">Contentful</span>
              <span class="tag">Figma</span>
              <span class="tag">Heroku</span>
              <span class="tag">Redux</span>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
export default {
  name: 'ResumeSection'
}
</script>

<style scoped lang="scss">
  @import '../assets/css/index.scss';

  #resume-content {
    display: grid;
    grid-template-areas:
      'summary'
      'experience'
      'education';
    grid-gap: 20px;
    margin-top: 36px;

    @media screen and (min-width: 920px) {
      grid-template-areas:
        'summary experience'
        'education experience';
    }
  }

  #summary {
    grid-area: summary;

    ul {
      margin-bottom: 16px !important;
      padding: 0;
      list-style: none;
    }
  } 

  #experience {
    grid-area: experience;
  }
  
  #education {
    grid-area: education;
  }
  
  .resume-title {
    font-size: 26px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 20px;
    color: $darkGray;
  }

  .resume-item {
    padding: 0 0 24px 20px;
    margin-top: -2px;
    border-left: 2px solid $colorSecondary;
    position: relative;
  }

  .resume-item h4 {
    line-height: 18px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    color: $colorPrimary;
    margin-bottom: 8px;
  }

  .locationDate {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    h5 {
      margin: 0;
      font-size: 18px;
    }
  }

  .resume-item ul {
    margin-bottom: 16px !important;
    padding: 0 20px 0 20px;
  }

  .resume-item ul li {
    padding-top: 8px;
  }

  .resume-item:last-child {
    padding-bottom: 0;
  }

  .resume-item::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    left: -9px;
    top: 0;
    background: $colorSecondary;
    border: 2px solid $colorSecondary;
  }

  .tag {
    font-size: 12px;
    background: $lightGray;
    border-radius: 5px;
    padding: 5px 10px;
    display: inline-block;
    font-weight: 600;
    margin: 4px;
  }

</style>