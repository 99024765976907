<template>
  <div>
    <header>
      <NavBar />
    </header>

    <main>
      <HeroSection />
      <SkillsSection />
      <ResumeSection />
      <ProjectsSection />
      <TestimonialsSection />
    </main>
    <footer>
      <FooterSection />
    </footer>
  </div>
</template>

<script>
import NavBar from './NavBar.vue';
import HeroSection from './HeroSection.vue';
import SkillsSection from './SkillsSection.vue';
import ResumeSection from './ResumeSection.vue';
import ProjectsSection from './ProjectsSection.vue';
import TestimonialsSection from './TestimonialsSection.vue';
import FooterSection from './FooterSection.vue';

export default {
  name: 'HomePage',
  components: {
    NavBar,
    HeroSection,
    SkillsSection,
    ResumeSection,
    ProjectsSection,
    TestimonialsSection,
    FooterSection
  }
}
</script>
