<template>
  <div>
      <div class="container">
        <span>Made with Vue and Vue Bootstrap. Deployed on Netlify.</span>
        <p>&copy; 2024 Fernanda Toledo</p>
      </div>
    </div>
</template>

<script>
export default {
  name: 'FooterSection'
}
</script>


<style scoped lang="scss">
  @import '../assets/css/index.scss';

  div {
    text-align: center;
    padding: 10px;
    font-size: 12px;


    p {
      margin: 0;
    }
  }
</style>