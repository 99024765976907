<template>
  <div id="hero">
    <div class="container">
      <div class="row">
          <div class="col-lg-8 col-sm-12">
            <h1>Fernanda Toledo</h1>
            <p>Frontend Developer with over 5 years of experience developing innovative and engaging web applications with React, JavaScript/TypeScript, HTML, CSS, Next.js, GraphQL and more.</p>

            <p>Committed to delivering pixel-perfect websites that reflect the company goals, design vision and user needs.</p>
            <SocialLinks id="social-links" />
          </div>
          <img id="deskImg" src="../assets/img/desk.png" alt="Computer Desk Vecteezy.com">
        </div>
    </div>
  </div>
</template>

<script>
import SocialLinks from './SocialLinks.vue';

export default {
  name: 'HeroSection',
  components: {
    SocialLinks
  }
}
</script>

<style scoped lang="scss">
  @import '../assets/css/index.scss';

  #hero {
    display: flex;
    justify-content: center;
    align-items: start;
    padding-top: 80px;
    width: 100%;
    height: 100vh;

    @media screen and (min-width: 920px) {
      justify-content: left;
    }

    @media screen and (min-width: 1100px) {
      padding-top: 0;
      align-items: center;
    }

    h1 {
      display: inline;
      position: relative;
      font-weight: bold;
      color: $colorPrimary;
      word-break: keep-all;
      white-space: nowrap;

      // border on bottom of name
      &:before {
        content: '';
        position: absolute;
        bottom: 0.0em;
        height: 0.5em;
        width: 100%;
        z-index: -1;
        display: inline-block;
        background-color: rgba($colorSecondary, 0.4);
      }

      @media screen and (min-width: 768px) {
        font-size: 56px;
      }
    }

    p {
      font-size: 18px;

      @media screen and (min-width: 768px) {
        font-size: 20px;
      }
    }

    p:first-of-type {
      margin-top: 34px;
    }

    #deskImg {
      position: absolute;
      right: auto;
      left: auto;
      bottom: -4px;
      width: 100%;
      max-width: 500px;
      filter: opacity(0.8);

      @media screen and (min-width: 768px) {
        right: 20px;
      }
    }
  }

  #social-links {
    margin-top: 16px;
    padding-left: 10px;
    justify-content: left;
  }
</style>