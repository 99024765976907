<template>
  <section id="projects" class="projects section-bg">
      <div class="container" >

        <div class="section-title">
          <h2>Projects</h2>
          <p>During my experience at Dapper Labs, I had the opportunity to contribute to the creation and development of multiple features on NBA Top Shot and CryptoKitties web applications, two of the most successful and popular blockchain games.</p>
        </div>        

        <div class="row projects-container">
          <div class="col-lg-6 col-md-6 projects-item filter-card">
            <a href="https://nbatopshot.com/" target="_blank" title="NBA Top Shot">
              <div class="projects-wrap">
                <img src="../assets/img/projects/nbats.jpeg" class="img-fluid" alt="">
                <div class="projects-info">
                  <h4>NBA Top Shot</h4>
                  <p><span class="hiddenMobile">Own basketball’s greatest Moments with NBA Top Shot.</span> NBA Top Shot is an NFT marketplace where sports fans can buy, sell and trade basketball video clips.</p>
                </div>
              </div>
            </a>
          </div>

          <div class="col-lg-6 col-md-6 projects-item filter-web">
            <a href="https://www.cryptokitties.co/" target="_blank" title="CryptoKitties">
              <div class="projects-wrap">
                <img src="../assets/img/projects/ck.jpeg" class="img-fluid" alt="">
                <div class="projects-info">
                  <h4>CryptoKitties</h4>
                  <p>CryptoKitties is the world's most successful blockchain game, accounting for 25% of the traffic on the Ethereum network upon launch. <span class="hiddenMobile">They are crypto collectibles that secure ownership of digital art via distributed ledger technology.</span></p>
                </div>
              </div>
            </a>
          </div>
        </div>

      </div>
    </section>
</template>

<script>
export default {
  name: 'ProjectsSection'
}
</script>

<style scoped lang="scss">
  @import '../assets/css/index.scss';

  #projects {
    min-height: 100vh;
  }

  .projects-container {
    margin-top: 40px;
  }

  .projects-item {
    margin-bottom: 30px;
  }

  .projects-wrap {
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background: rgba(69, 80, 91, 0.8);
  }

  .projects-wrap::before {
    content: "";
    background: rgba(255, 255, 255, 0.9);
    position: absolute;
    left: 30px;
    right: 30px;
    top: 30px;
    bottom: 30px;
    transition: all ease-in-out 0.3s;
    z-index: 2;
    opacity: 0;
  }

  .projects-wrap .projects-info {
    opacity: 0;
    padding: 10px;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 3;
    transition: all ease-in-out 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .projects-wrap .projects-info::before {
    display: block;
    content: "";
    width: 48px;
    height: 48px;
    position: absolute;
    top: 35px;
    left: 35px;
    border-top: 3px solid #d7dce1;
    border-left: 3px solid #d7dce1;
    transition: all 0.5s ease 0s;
    z-index: 9994;
  }

  .projects-wrap .projects-info::after {
    display: block;
    content: "";
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: 35px;
    right: 35px;
    border-bottom: 3px solid #d7dce1;
    border-right: 3px solid #d7dce1;
    transition: all 0.5s ease 0s;
    z-index: 9994;
  }

  .projects-wrap .projects-info h4 {
    font-size: 20px;
    color: $darkGray;
    font-weight: 600;

    @media (max-width: 991px) {
      font-size: 14px;
    }
  }

  .projects-wrap .projects-info p {
    color: $darkGray;
    font-size: 14px;
    padding: 0;
    margin: 0;

    @media (max-width: 991px) {
      font-size: 10px;
    }
  }

  .projects-wrap:hover::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
  }

  .projects-wrap:hover .projects-info {
    opacity: 1;
  }

  .projects-wrap:hover .projects-info::before {
    top: 15px;
    left: 15px;
  }

  .projects-wrap:hover .projects-info::after {
    bottom: 15px;
    right: 15px;
  }
</style>